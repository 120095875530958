import React, { useState, useContext, useEffect } from "react";
import Billing from "./Billing";
import YourOrder from "./YoursOrder";
import PaymentModes from "./PaymentMode";
import { AppContext } from "~/components/context/AppContext";
import validateAndSanitizeCheckoutForm from "~/validator/checkout";
import { useMutation, useQuery } from "@apollo/client";
import { getFormattedCart, createCheckoutData } from "~/utils/functions";
import OrderSuccess from "./OrderSuccess";
import GET_CART from "~/queries/get-cart";
import CHECKOUT_MUTATION from "~/mutations/checkout";
import CheckoutError from "./CheckoutError";
import {  Spinner } from '~/components/elements/Icon';
import { Loading } from "~/components/shop/Login";

const CheckoutForm = () => {
  // const initialState = {
  // 	firstName: '',
  // 	lastName: '',
  // 	company: '',
  // 	country: '',
  // 	address1: '',
  // 	address2: '',
  // 	city: '',
  // 	state: '',
  // 	postcode: '',
  // 	phone: '',
  // 	email: '',
  // 	createAccount: false,
  // 	username: '',
  // 	password: '',
  // 	customerNote: '',
  // 	paymentMethod: '',
  // 	errors: null
  // };

  // Use this for testing purposes, so you dont have to fill the checkout form over an over again.
  const initialState = {
    firstName: "Sam",
    lastName: "Shear",
    address1: "109 Hills Road Valley",
    address2: "Station Road",
    city: "Pune",
    state: "Maharastra",
    country: "IN",
    postcode: "400298",
    phone: "9959338989",
    email: "dev@together.agency",
    company: "Tech",
    createAccount: false,
    username: '',
    password: '',
    customerNote: "My Order notes",
    paymentMethod: "cod",
    errors: null,
  };

  const [cart, setCart] = useContext(AppContext);
  const [input, setInput] = useState(initialState);
  const [orderData, setOrderData] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const [fetchedCart, setFetchedCart] = useState(false);

  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      // console.warn( 'completed GET_CART' );

      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(data);
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart));

      // Update cart data in React Context.
      setCart(updatedCart);
      setFetchedCart(true)
    },
  });

  // Checkout or CreateOrder Mutation.
  const [
    checkout,
    { data: checkoutResponse, loading: checkoutLoading },
  ] = useMutation(CHECKOUT_MUTATION, {
    variables: {
      input: orderData,
    },
    onCompleted: () => {
      // console.warn( 'completed CHECKOUT_MUTATION' );
      refetch();
    },
    onError: (error) => {
      if (error) {
        setRequestError(error);
      }
    },
  });

  /*
   * Handle form submit.
   *
   * @param {Object} event Event Object.
   *
   * @return {void}
   */
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const result = validateAndSanitizeCheckoutForm(input);
    if (!result.isValid) {
      setInput({ ...input, errors: result.errors });
      return;
    }
    const checkOutData = createCheckoutData(input);
    setOrderData(checkOutData);
    setRequestError(null);
  };

  /*
   * Handle onchange input.
   *
   * @param {Object} event Event Object.
   *
   * @return {void}
   */
  const handleOnChange = (event) => {
    if ("createAccount" === event.target.name) {
      const newState = { ...input, [event.target.name]: !input.createAccount };
      setInput(newState);
    } else {
      const newState = { ...input, [event.target.name]: event.target.value };
      setInput(newState);
    }
  };

  useEffect(() => {
    if (null !== orderData) {
      // Call the checkout mutation when the value for orderData changes/updates.
      /* eslint-disable */
      checkout();
    }
  }, [orderData]);

  return (
    <section className="section bg-blue-light">
      {fetchedCart ? (
        <div className="container">
          {cart ? (
            <form onSubmit={handleFormSubmit} className="woo-next-checkout-form">
              <div className="grid lg:gap-y-0 gap-10 lg:grid-cols-2">
                {/*Billing Details*/}
                <div className="space-y-5">
                  <h2 className="t-32">Billing Details</h2>
                  <Billing input={input} handleOnChange={handleOnChange} />
                </div>
                {/* Order & Payments*/}
                <div className="space-y-5">
                  <div className="relative h-full">
                    <div className="top-28 sticky">
                      {/*	Order*/}
                      <h2 className="t-32">Your Order</h2>
                      <YourOrder cart={cart} />

                      {/*Payment*/}
                      <PaymentModes input={input} handleOnChange={handleOnChange} />
                      <div className="woo-next-place-order-btn-wrap mt-5">
                        <button
                          className="woo-next-large-black-btn woo-next-place-order-btn  button button-primary"
                          type="submit"
                          disabled={checkoutLoading}
                        >
                          <span className="!flex justify-center items-center">Place Order {checkoutLoading && <Loading />}</span>
                          <span className="!flex justify-center items-center" aria-hidden={true}>Place Order {checkoutLoading && <Loading />}</span>
                        </button>
                      </div>
                      {requestError && <CheckoutError requestError={requestError} />}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            ""
          )}

          {/*Show message if Order Success*/}
          <OrderSuccess response={checkoutResponse} />
        </div>
        ) : (
          <div className="container py-40 flex items-center justify-center text-navy">
            <div className="w-16 fw-svg">
              <Spinner /> 
            </div>
          </div>
        )}
    </section>
  );
};

export default CheckoutForm;
